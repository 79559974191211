import { combineReducers } from "redux";
import homeReducer from "./reducers/";
import reducerUpdateImage from "./reducers/reducerUpdateImage"

const reducers = combineReducers({
    homeReducer,
    reducerUpdateImage
});

export default reducers;
