import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers.js';
import { generator } from './sagas/generators';
import { createBrowserHistory } from 'history';

import persistAuthLocally from './persist-auth-locally';

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();
const historyRouterMiddleware = routerMiddleware(history);

let retrievedState;
try {
	retrievedState = localStorage.getItem('authStore');
	if (retrievedState === null) {
		retrievedState = {};
	}
	retrievedState = JSON.parse(retrievedState);
} catch (err) {
	retrievedState = {};
}

const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose;

const enhancer = composeEnhancers(
	applyMiddleware(sagaMiddleware, loggerMiddleware, historyRouterMiddleware, persistAuthLocally)
);

export const store = createStore(
	combineReducers({
		reducers,
		router: routerReducer,
	}),
	enhancer
);

sagaMiddleware.run(generator);
