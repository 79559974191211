import React from 'react';

import { Button } from 'reactstrap';
import {mercadoPagoBuy} from 'actions';
import { connect } from 'react-redux';
import logoMercadoPago from "assets/img/logoMercadoPago.svg";

const ButtonMercadoPago = (props) => {
	const {productSelected, user, typeProduct} = props;
	const compra = { productSelected, user, typeProduct};
	
	const handlerComprar =(_compra) =>{
	   props.mercadoPagoCompra(_compra);
    }

	return (
        <Button onClick={() => handlerComprar(compra)} className="btn-round btn-just-icon" color="success">
			<img src={logoMercadoPago} alt="Pagar con MercadoPago" />
	    </Button>
	);
};


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
        mercadoPagoCompra:(compra) => dispatch(mercadoPagoBuy(compra)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonMercadoPago);