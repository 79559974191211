import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getHomeSliderMobile, newHomeSliderMobile, updateEditedHomeSliderMobile } from 'actions';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import Loader from 'assets/img/loadern.gif';

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	Container,
	Row,
	Col,
} from 'reactstrap';

class SliderMobile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			homeSliderMobile: {
				title:'Slider',
				description:'slider',
				avatar:''
			},
			cambio:true,
			imagenes: '',
			msg: '',
			alert: null,
			loading:false,
		};
	}

	static getDerivedStateFromProps(props, state) {
        if(props.homeSliderMobile){
		if (props.homeSliderMobile !== state.homeSliderMobile) {
			return { //homeSliderMobile: props.homeSliderMobile[0] 
			};
        } else return null;
    }
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.homeSliderMobile !== this.props.homeSliderMobile) {
			this.setState({
				...this.state,
				homeSliderMobile: this.props.homeSliderMobile[0],
				//homeSliderMobileData: this.props.homeSliderMobile[0],
				editMode: true,
				selectedFiles: null,
			});
		}
		if (prevProps.loading !== this.props.loading) {
			this.setState({
				...this.state,
				//homeSliderMobile: this.props.homeSliderMobile[0],
				//homeSliderMobileData: this.props.homeSliderMobile[0],
				loading: this.props.loading,
			});
		}
	}

	componentDidMount() {
		this.setState({
			homeSliderMobile: this.props.homeSliderMobile[0],
		    homeSliderMobileData: this.props.homeSliderMobile[0],
			selectedFiles: null,
        });
	}



	//ES PARA IMAGEN
	onDrop = (acceptedFiles) => {
		let files = [];
		let st = [];
		if (!this.state.selectedFiles) {
			st = acceptedFiles;
		} else {
			files = this.state.selectedFiles;
			st = _.concat(files, acceptedFiles);
		}
		this.setState({
			selectedFiles: st,
		});

		this.handlerSend();
	};

	removeImageHandler = (e, x, whereIs) => {
		if (whereIs === 'images') {
			const images = this.state.homeSliderMobile.avatar !== '' ? _.split(this.state.homeSliderMobile.avatar, ',') : [];
			const imagenesFiltered = _.map(images, (m) => {
				if (m !== x) {
					return m;
				}
			});
			const filtered = _.compact(_.map(imagenesFiltered));
			const nueva_homeSlider = { ...this.state.homeSliderMobile, avatar: filtered };
			this.removeFile(x, nueva_homeSlider);
			this.setState({homeSliderMobile: nueva_homeSlider});

		} else {
			const { selectedFiles } = this.state;
			const selectedFiltered = _.filter(selectedFiles, (s) => s !== x);
			this.setState({ ...this.state, selectedFiles: selectedFiltered });
		}
	};

	removeFile = (fileName) => {
		if (fileName) {
			axios
				.post(
					'https://calendar-apirest.herokuapp.com/delete-file',
					{ fileName: fileName },
					{
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
						},
					}
				)
				.then((response) => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							console.log(response.data.error);
						} else {
							// Success
							console.log('filedata removed', fileName);
							this.handlerSend();
						}
					}
				})
				.catch((error) => {
					// If another error
					console.log(error);
				});
		} else {
			// if file not selected throw error
			console.log('Send a fileName to remove');
		}
	};

	singleFileChangedHandler = (event) => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	multipleFileChangedHandler = (files) => {
		this.setState({
			selectedFiles: files,
		});
	};

	showtheImages = () => {
		const { selectedFiles } = this.state;

		const images = this.state.homeSliderMobile.avatar !== '' ? _.split(this.state.homeSliderMobile.avatar, ',') : [];
		let oldImages = null;
		oldImages = _.map(images, (x) => {
			if (x !== '' && x !== 'undefined') {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						{this.props.loading ? (
							''
						) : (
							<Button
								className="btn-icon btn-round"
								color="pinterest"
								onClick={(e) => {
									this.removeImageHandler(e, x, 'images');
								}}
							>
								<i style={{ fontSize: '20px' }} className="tim-icons icon-simple-remove" />
							</Button>
						)}
						<img
							alt=""
							width="140px"
							src={`https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/${x}`}
						></img>
					</div>
				);
				return img;
			} else return null;
		});

		const returnImages =
			selectedFiles &&
			_.map(selectedFiles, (x) => {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						<img alt="..." src={Loader} width={'70px'} />
						<h6>CARGANDO IMAGENES...</h6>
					</div>
				);
				return img;
			});

		return (
			<div>
				{oldImages}
				{returnImages}
			</div>
		);
	};

	handlerSend = () => {
        const data = new FormData();
        const { _id } = this.props?.homeSliderMobile[0];
		const { homeSliderMobile, selectedFiles } = this.state;

		if (selectedFiles) {
			for (let i = 0; i < selectedFiles.length; i++) {
				data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
			}
		}

		data.append('title', 'titleSlider');
		data.append('avatar', homeSliderMobile.avatar);
		data.append('description', 'descriptionSlider');


		console.log(homeSliderMobile.avatar);
		this.props.updateEditedHomeSliderMobile(data, _id);
		//this.props.newHomeSliderMobile(data);
	};

	componentUploadImage = () => {
		const maxSize = 3000000;
		const component = (
			<div>
				<Dropzone onDrop={this.onDrop} accept="image/png, image/jpeg" minSize={0} maxSize={maxSize}>
					{({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
						const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
						return (
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<div
									style={{
										width: '100%',
										height: '200px',
										border: '5px dashed #dadada',
										marginBottom: '30px',
										textAlign: 'center',
										color: '#dadada',
									}}
								>
									{this.props.loading ? (
										<img alt="..." style={{ marginTop: '30px' }} src={Loader} width={'70px'} />
									) : (
										<i
											style={{ fontSize: '60px', marginTop: '30px' }}
											className="icon-line2-picture"
										/>
									)}
									<h6 style={{ marginTop: '20px' }}>
										{!isDragActive && 'Click aqui o arrastra y suelta tus imagenes'}
										{isDragActive && !isDragReject && 'Sueltalo como si te estuviera quemando!'}
										{isDragReject && 'Este tipo de formato no lo aceptamos! JPG o PNG '}
									</h6>
									{isFileTooLarge && (
										<div className="text-danger mt-2">Imagen demasiado pesada max 3Mb</div>
									)}
								</div>
							</div>
						);
					}}
				</Dropzone>
				<div>
					<h4>Imagenes Slider Mobile - Resolución 600x700  </h4>
				</div>
				<div>{this.state.homeSliderMobile ? this.showtheImages() : ''}</div>
			</div>
		);
		return component;
	};
	
	render() {
		return this.props.loading ? (
			<div style={{ width: '100%', textAlign: 'center',color:'#fff' }}>
				<img src={Loader} width="100px" alt="" />
				loading...
			</div>
		) : (
			<>
				<Container>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '50px' }}></div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ padding: '0px' }}>
					<Container>
						<Row>
							<Col className="mr-auto ml-auto" md='9'>
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										<Row>
										<Col className="mr-auto ml-auto" md="12" style={{ padding: '20px' }}>
												{this.componentUploadImage()}
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getHomeSliderMobile: () => dispatch(getHomeSliderMobile()),
		newHomeSliderMobile: (data) => dispatch(newHomeSliderMobile(data)),
		updateEditedHomeSliderMobile: (data, id) => dispatch(updateEditedHomeSliderMobile(data, id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderMobile);

